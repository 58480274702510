import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { getElementWidth } from "../../utils/ScreenManager";

const ImiSearchResult = ({ item, openGameFn }) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));

  const classes = useStyles();
  //#endregion

  //#region HOOKS & APIS
  //#endregion

  return (
    <Card
      elevation={0}
      onClick={() => {
        openGameFn(item.item_url, item.additional_info.uuid, true);
      }}
      style={{
        display: "flex",
        width: "100%",
        height: getElementWidth(220),
        marginTop: getElementWidth(20),
        paddingLeft: getElementWidth(15),
        paddingRight: getElementWidth(15),
      }}
    >
      <CardMedia
        image={item.item_cover_image}
        title={item.item_name}
        style={{
          width: getElementWidth(170),
          height: "100%",
          borderRadius: 0,
        }}
      />
      <CardContent
        style={{
          padding: 0,
          paddingLeft: getElementWidth(15),
          width: getElementWidth(350),
        }}
      >
        <Typography
          color="text.primary"
          fontWeight="bold"
          fontSize={getElementWidth(26)}
        >
          {" "}
          {item.item_name}
        </Typography>
        <Typography color="text.secondary" fontSize={getElementWidth(22)}>
          {" "}
          {item.item_description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ImiSearchResult;
