import { Menu, PlayCircleOutline } from "@mui/icons-material";
import { IconButton, ImageListItem, ImageListItemBar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ImiCategoryTypes } from "../../utils/ImiUtilData";
import { getElementWidth } from "../../utils/ScreenManager";

const ImiImageItem = ({ item, type, width, openGameFn, ...restProps }) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    middleIcon: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      "-ms-transform": "translate(-50%, -50%)",
    },
  }));

  const classes = useStyles();
  //#endregion

  //#region HOOKS & APIS

  //#endregion

  return (
    <ImageListItem {...restProps} style={{ marginRight: getElementWidth(15) }}>
      <input
        type="image"
        src={item.item_cover_image}
        alt={item.item_name}
        onClick={() => {
          if (
            type === ImiCategoryTypes.GAME ||
            type === ImiCategoryTypes.PROMO_GAME ||
            type === ImiCategoryTypes.BETA_GAME
          ) {
            openGameFn(item.item_url, item.additional_info.uuid, true);
          } else {
            openGameFn(item.item_url);
          }
        }}
        height="100%"
        width={width}
      />
      {type === ImiCategoryTypes.VIDEO && (
        <div className={classes.middleIcon}>
          <PlayCircleOutline fontSize={"large"} color="primary.light" />
        </div>
      )}
      {type === ImiCategoryTypes.BETA && (
        <ImageListItemBar
          classes={{
            root: classes.titleBar,
            title: classes.title,
          }}
          actionIcon={
            <IconButton aria-label={`star ${item.item_name}`}>
              <Menu color="primary.light" />
            </IconButton>
          }
        />
      )}
    </ImageListItem>
  );
};

export default ImiImageItem;
