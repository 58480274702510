import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { getElementWidth } from "../../utils/ScreenManager";

const ImiSubItem = (props) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));

  const classes = useStyles();

  //#endregion

  const { item, onUnSub } = props;
  const unSubHandler = () => {
    onUnSub(item.uuid);
  };

  return (
    <Card
      style={{
        display: "flex",
        width: "100%",
        height: getElementWidth(240),
        marginTop: getElementWidth(20),
        paddingLeft: getElementWidth(15),
        paddingRight: getElementWidth(15),
      }}
    >
      <CardMedia
        style={{
          width: getElementWidth(200),
          height: "100%",
          borderRadius: 0,
        }}
        image={item.cover_image != null ? item.cover_image : "/loading.jpg"}
        title={item.name}
        sx={{ backgroundColor: "background.default" }}
      />
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: getElementWidth(15),
          padding: 0,
          width: getElementWidth(520),
          height: getElementWidth(240),
          position: "relative",
        }}
        sx={{ backgroundColor: "background.default" }}
      >
        <Typography
          color="text.primary"
          style={{
            fontWeight: "bold",
            fontSize: getElementWidth(26),
          }}
        >
          {item.name}
        </Typography>
        <Typography
          color="text.secondary"
          style={{
            fontSize: getElementWidth(22),
          }}
          noWrap={true}
        >
          {item.description}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={unSubHandler}
          style={{
            fontSize: getElementWidth(18),
            textTransform: "none",
            position: "absolute",
            bottom: 0,
            right: getElementWidth(20),
          }}
          disableElevation
        >
          Unsubscribe
        </Button>
      </CardContent>
    </Card>
  );
};

export default ImiSubItem;
