import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useGetPopularListQuery } from "../../services/catalog";
import DummyBottomSpace from "../../utils/DummyBottomSpace";
import FeedbackDialog from "../../utils/FeedbackDialog";
import { getElementWidth } from "../../utils/ScreenManager";
import ImiSearchResult from "./ImiSearchResult";

const ImiSearchPopular = ({ openGameFn }) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));

  const classes = useStyles();
  //#endregion

  //#region HOOKS & APIS

  //useGetPopularListQuery
  const { data, error, isLoading } = useGetPopularListQuery({ size: 10 });

  //#endregion

  //#region POPULAR LIST API CONTENT
  let popularListAPIContent = null;

  if (isLoading) {
    popularListAPIContent = (
      <Backdrop
        open={true}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
    );
  } else if (error) {
    //catalogListAPIContent = <Typography>Oh no, there was an error</Typography>;

    popularListAPIContent = (
      <FeedbackDialog
        id="Popular List API Error Dialog"
        open={true}
        onClose={() => {
          window.location.reload();
        }}
        data={{
          title: "Oops!",
          msg: "Something Went Wrong",
        }}
      />
    );
  } else {
    if (data) {
      try {
        popularListAPIContent = data.map((item) => {
          return (
            <Grid item xs={12} key={item.additional_info.game_id}>
              <ImiSearchResult item={item} openGameFn={openGameFn} />
            </Grid>
          );
        });
      } catch (error) {
        popularListAPIContent = (
          <FeedbackDialog
            id="Popular List API Error Dialog"
            open={true}
            onClose={() => {
              window.location.reload();
            }}
            data={{
              title: "Oops!",
              msg: "Something Went Wrong",
            }}
          />
        );
      }
    } else {
      popularListAPIContent = (
        <FeedbackDialog
          id="Popular List API Error Dialog"
          open={true}
          onClose={() => {
            window.location.reload();
          }}
          data={{
            title: "Oops!",
            msg: "Something Went Wrong",
          }}
        />
      );
    }
  }
  //#endregion

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          color="text.primary"
          fontWeight="bold"
          fontSize={getElementWidth(28)}
          className={classes.text}
          style={{
            paddingLeft: getElementWidth(15),
            marginTop: getElementWidth(25),
          }}
        >
          {" "}
          Most Popular Games
        </Typography>
      </Grid>
      {popularListAPIContent}
      <DummyBottomSpace />
    </Grid>
  );
};

export default ImiSearchPopular;
