import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ImiPathsUrls } from "../../utils/ImiUtilData";

const ImiGameFrame = () => {
  //#region HOOKS
  const userToken = useSelector((state) => state.home.userToken);
  const urlData = useSelector((state) => state.home.activeUrlData);
  const navigate = useNavigate();

  useEffect(() => {
    if (urlData === null) {
      navigate(ImiPathsUrls.home, {
        replace: true,
      });
    }
  }, []);

  //#endregion

  let url = "";

  if (urlData) {
    url = `${urlData.url}?platform=pwa&version=200`;

    if (urlData.isGame) {
      if (userToken) {
        url += `&user=${userToken}`;
      }
    }
  }

  return (
    <iframe
      id="gameIframe"
      title="gameFrame"
      //src="https://d1t9hyhvlamggx.cloudfront.net/game_bundle/staging/a8a03f7d-254e-4a76-9219-8ae3e0659660/v9.0/index.html?user=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJLYWxhbmEiLCJhdXRob3JpdGllcyI6WyJST0xFX0RFViJdLCJpYXQiOjE2MjAzNzcxODEsImV4cCI6MTY1MTkxMzE4MSwidWQiOiI1ZDFjOTU5OS1mY2M3LTQ2ZDMtYmQ2YS1hZjhiMmJmNjQxOWUiLCJ0eXBlIjoiQUNDRVNTIn0.gsQbBX3ALI9dKSD2qMDcw18-z-AQu_WHvBjxqHVdzxM&amp;language=ENGLISH&amp;platform=pwa&amp;isWebview=false"
      src={url}
      allow="microphone; accelerometer; encrypted-media"
      //sandbox="allow-same-origin allow-scripts allow-modals"
      style={{
        width: "100%",
        height: "100%",
        border: "0",
        position: "fixed",
        top: "0",
        left: "0",
        overflow: "hidden",
      }}
    ></iframe>
  );
};

export default ImiGameFrame;
