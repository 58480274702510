import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useLayoutEffect } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetCatalogListQuery } from "../../services/catalog";
import { useGetGameDetailsMutation } from "../../services/game";
import { homeActions } from "../../store";
import DummyBottomSpace from "../../utils/DummyBottomSpace";
import FeedbackDialog from "../../utils/FeedbackDialog";
import { DATA_KEY } from "../../utils/ImiUtilData";
import ImiImageList from "./ImiImageList";

const ImiHome = ({ openGameFn }) => {
  //#region HOOKS & APIS
  const userToken = useSelector((state) => state.home.userToken);
  const dispatch = useDispatch();

  //CATALOG API
  const {
    data: catalogAPIResponse,
    error: catalogAPIError,
    isLoading: isLoadingCatalogAPI,
  } = useGetCatalogListQuery();

  //GET GAME DATA API
  const [
    getGameUrl,
    {
      data: getGameApiResponse,
      isLoading: isLoadingGetGameApi,
      error: getGameApiError,
    },
  ] = useGetGameDetailsMutation();

  useLayoutEffect(() => {
    dispatch(homeActions.setActiveSubScreen(null));
  }, [dispatch]);

  useEffect(() => {
    //localStorage.removeItem("token");
    //RETRIEVE DATA ON PAGE REFRESH
    if (userToken === null) {
      let _userData = localStorage.getItem(DATA_KEY);

      if (_userData) {
        //dispatch(homeActions.setUserToken(_userData));
        _userData = JSON.parse(_userData);

        dispatch(homeActions.setUserToken(_userData.token));
        dispatch(
          homeActions.setUserData({
            name: _userData.name,
            image: _userData.image,
          })
        );
      }
    }

    console.log("1.0V");

    //CHECK FOR GAME ID IN URL
    const url = new URL(window.location.href);
    const gameId = url.searchParams.get("gameId");
    if (gameId) {
      getGameUrl({ uuid: gameId });
    }

    //ONE SIGNAL
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;

    OneSignal.push(() => {
      OneSignal.init({
        appId: "0d48e954-f5c6-47d3-8c2d-1e3790bc848e",
      });
    });
    OneSignal.push([
      "addListenerForNotificationOpened",
      function (data) {
        //console.log("Received NotificationOpened:");
        //console.log(data);
        if (data) {
          let newUrl = new URL(data.data.url);
          let linkUrl = newUrl.searchParams.get("url");
          let gameUUID = newUrl.searchParams.get("uuid");
          openGameFn(linkUrl, gameUUID, true);
        }
      },
    ]);
  }, []);

  useEffect(() => {
    if (getGameApiResponse) {
      openGameFn(getGameApiResponse.url, getGameApiResponse.uuid, true);
    }
  }, [getGameApiResponse]);

  //#endregion

  //#region CATALOG LIST API STATE
  let catalogListAPIContent;

  if (isLoadingCatalogAPI) {
    catalogListAPIContent = (
      <Backdrop
        open={true}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
    );
  } else if (catalogAPIError) {
    catalogListAPIContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          window.location.reload();
        }}
        data={{
          title: "Oops!",
          msg: "Something Went Wrong",
        }}
      />
    );
  } else {
    if (catalogAPIResponse) {
      try {
        catalogListAPIContent = catalogAPIResponse.map((item) => {
          //console.log(item);
          return (
            <ImiImageList
              title={item.catalog_name}
              type={item.catalog_type}
              key={item.catalog_id}
              isListLoading={false}
              id={item.catalog_id}
              openGameFn={openGameFn}
            />
          );
        });
      } catch (error) {
        catalogListAPIContent = (
          <FeedbackDialog
            open={true}
            onClose={() => {
              window.location.reload();
            }}
            data={{
              title: "Oops!",
              msg: "Something Went Wrong",
            }}
          />
        );
      }
    } else {
      catalogListAPIContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            window.location.reload();
          }}
          data={{
            title: "Oops!",
            msg: "Something Went Wrong",
          }}
        />
      );
    }
  }
  //#endregion

  //#region GET GAME DATA API STATES
  let getGameApiContent;
  if (isLoadingGetGameApi) {
    getGameApiContent = (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  //#endregion

  return (
    <Fragment>
      {catalogListAPIContent}
      {getGameApiContent}
      <DummyBottomSpace />
    </Fragment>
  );
};

export default ImiHome;
