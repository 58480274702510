import { grey } from "@mui/material/colors";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useWindowSize } from "./hooks/useWindowSize";
import { ImiPathsUrls, IMIThemeMode } from "./utils/ImiUtilData";
import { Navigate, Route, Routes } from "react-router-dom";
import ImiAppBars from "./components/AppBars";
import { screenSizeData } from "./utils/ScreenManager";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ImiHome from "./components/Home";
import ImiProfile from "./components/Profile";
import ImiGameFrame from "./components/Game/ImiGameFrame";
import ImiSearch from "./components/Search";
import PageNotFound from "./utils/PageNotFound";
import ImiSubscriptions from "./components/Subscriptions";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { homeActions } from "./store";

export const ThemeColors = {
  backgroundColor: {
    light: grey[300],
    //dark : 'rgba(0, 0, 0, 0.54)'
    dark: "rgba(0, 0, 0, 255)",
  },
  appBarColor: {
    light: "rgba(255,255,255,255)",
    dark: "rgba(33,33,33,255)",
  },
  primaryTextColor: {
    light: "rgba(0, 0, 0, 255)",
    dark: "rgba(255,255,255,255)",
  },
  secondaryTextColor: {
    light: grey[700],
    dark: grey[500],
  },
  bottomNavColor: {
    light: "rgba(255,255,255,255)",
    //dark : grey[800]
    dark: "rgba(48,48,48,255)",
  },
  bottomNavSelectColor: {
    light: "rgba(206, 60, 54,255)",
    dark: "rgba(206, 60, 54,255)",
  },
  backDropColor: {
    light: "rgba(0,0,0,255)",
    dark: "rgba(33,33,33,100)",
  },
};

function App() {
  //#region HOOKS & APIS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //#endregion

  //#region THEME DATA
  [screenSizeData.currentScreenWidth, screenSizeData.currentScreenHeight] =
    useWindowSize();

  const themeMode = useSelector((state) => state.home.themeMode);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          primary: {
            main: "rgba(206, 60, 54,255)",
            light: "rgba(206, 60, 54,255)",
            dark: "rgba(206, 60, 54,255)",
          },
          secondary: {
            main: "rgba(206, 60, 54,255)",
          },
          icon: {
            main:
              themeMode === IMIThemeMode.LIGHT
                ? ThemeColors.primaryTextColor.light
                : ThemeColors.primaryTextColor.dark,
          },
          background: {
            default:
              themeMode === IMIThemeMode.LIGHT
                ? ThemeColors.backgroundColor.light
                : ThemeColors.backgroundColor.dark,
            paper:
              themeMode === IMIThemeMode.LIGHT
                ? ThemeColors.backgroundColor.light
                : ThemeColors.backgroundColor.dark,
          },
          text: {
            primary:
              themeMode === IMIThemeMode.LIGHT
                ? ThemeColors.primaryTextColor.light
                : ThemeColors.primaryTextColor.dark,
            secondary:
              themeMode === IMIThemeMode.LIGHT
                ? ThemeColors.secondaryTextColor.light
                : ThemeColors.secondaryTextColor.dark,
          },
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                backgroundColor:
                  themeMode === IMIThemeMode.LIGHT
                    ? ThemeColors.appBarColor.light
                    : ThemeColors.appBarColor.dark,
              },
            },
          },
          MuiBottomNavigation: {
            styleOverrides: {
              root: {
                backgroundColor:
                  themeMode === IMIThemeMode.LIGHT
                    ? ThemeColors.bottomNavColor.light
                    : ThemeColors.bottomNavColor.dark,
              },
            },
          },
          MuiBottomNavigationAction: {
            styleOverrides: {
              root: {
                "&.Mui-selected": {
                  color:
                    themeMode === IMIThemeMode.LIGHT
                      ? ThemeColors.bottomNavSelectColor.light
                      : ThemeColors.bottomNavSelectColor.dark,
                },
              },
            },
          },
        },
      }),
    [themeMode]
  );

  //#endregion

  //#region COMMON METHODS
  const openGameFn = (url, uuid = null, isGame = false) => {
    dispatch(
      homeActions.setActiveUrlData({
        isGame: isGame,
        url: url,
      })
    );

    if (uuid) {
      dispatch(homeActions.setActiveGameUUID(uuid));
    }

    navigate(ImiPathsUrls.game, {
      state: {
        from: location,
      },
    });
  };
  //#endregion

  return (
    <ThemeProvider theme={theme}>
      <ImiAppBars>
        <Routes>
          <Route path="/" element={<Navigate to={ImiPathsUrls.home} />} />
          <Route
            path={ImiPathsUrls.home}
            element={<ImiHome openGameFn={openGameFn} />}
          />
          <Route
            path={ImiPathsUrls.search}
            element={<ImiSearch openGameFn={openGameFn} />}
          />
          <Route path={ImiPathsUrls.profile} element={<ImiProfile />} />
          <Route path={ImiPathsUrls.subs} element={<ImiSubscriptions />} />
          <Route path={ImiPathsUrls.game} element={<ImiGameFrame />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ImiAppBars>
    </ThemeProvider>
  );
}

export default App;
