import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <div
          id="innerDiv"
          style={{
            position: "relative",
            maxWidth: "100vw",
            height: "100vh",
            width: "576px",
          }}
        >
          <App />
        </div>
      </div>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
