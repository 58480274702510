import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useLayoutEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetSubsListQuery,
  useUnSubGameMutation,
} from "../../services/subsList";
import { homeActions } from "../../store";
import FeedbackDialog from "../../utils/FeedbackDialog";
import { SubScreens } from "../../utils/ImiUtilData";
import LoadingDialog from "../../utils/LoadingDialog";
import { getElementHeight, getElementWidth } from "../../utils/ScreenManager";
import ImiSubsList from "./ImiSubsList";
import UnSubConfirmationDialog from "./UnSubConfirmationDialog";

const ImiSubscriptions = () => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));

  const classes = useStyles();

  const styleConfig = {
    divP: {
      width: "100%",
      display: "flex",
      height: "80vh",
      justifyContent: "center",
      //backgroundColor : 'red'
    },
    typo: {
      //align-self: center;
      textTransform: "none",
      fontSize: getElementWidth(23),
      alignSelf: "center",
    },
  };
  //#endregion

  //#region HOOKS & APIS
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    //dispatch(homeActions.activateBack(true));
    dispatch(homeActions.setActiveSubScreen(SubScreens.MY_SUBS));
  }, [dispatch]);

  const userToken = useSelector((state) => state.home.userToken);

  //SUB LIST API
  const {
    error: subListDataError,
    isLoading: isLoadingSubListData,
    data: subListData,
  } = useGetSubsListQuery(userToken);

  //UNSUB API
  const [
    unSubGame,
    { error: unSubDataError, isLoading: isLoadingUnSubData, data: unSubData },
  ] = useUnSubGameMutation();

  //#endregion

  //#region SUB LIST API STATE
  const unSubGameHandler = (data) => {
    console.log(data);
    setOpenUnSubConfirmDialog({ state: true, uuid: data });
  };

  let subListAPIStateContent;

  if (isLoadingSubListData) {
    subListAPIStateContent = <LoadingDialog></LoadingDialog>;
  } else if (subListDataError) {
    console.log(subListDataError);
    subListAPIStateContent = (
      <div style={styleConfig.divP}>
        {" "}
        <Typography style={styleConfig.typo}>Error</Typography>
      </div>
    );
  } else if (subListData) {
    subListAPIStateContent =
      subListData?.length > 0 ? (
        <ImiSubsList data={subListData} onUnSub={unSubGameHandler} />
      ) : (
        <div style={styleConfig.divP}>
          {" "}
          <Typography style={styleConfig.typo}>
            You have not subscribed to our promo games yet!
          </Typography>
        </div>
      );
  } else {
    subListAPIStateContent = null;
  }
  //#endregion

  //#region UNSUB CONFIRM DIALOG
  const [openUnSubConfirmDialog, setOpenUnSubConfirmDialog] = useState({
    state: false,
    uuid: null,
  });

  const onUnSubConfirmDialogActionBtnClick = (state) => {
    if (state) {
      let game_uuid = openUnSubConfirmDialog.uuid;
      //unSubGame({userToken,gameUUID});
      //let token = null;
      unSubGame({ userToken, game_uuid });
      setUnSubAPIState(true);
    }
    setOpenUnSubConfirmDialog({ state: false, uuid: null });
  };

  let unSubConfirmDialog = (
    <UnSubConfirmationDialog
      open={openUnSubConfirmDialog.state}
      onClose={onUnSubConfirmDialogActionBtnClick}
    />
  );

  //#endregion

  //#region UNSUB API STATE
  const [unSubAPIState, setUnSubAPIState] = useState(false);

  let UnSubAPIStateContent;

  if (unSubAPIState) {
    if (isLoadingUnSubData) {
      UnSubAPIStateContent = <LoadingDialog></LoadingDialog>;
    } else if (unSubDataError) {
      console.log(unSubDataError);
      UnSubAPIStateContent = (
        <FeedbackDialog
          id="UnSub Error Dialog"
          open={true}
          onClose={() => {
            setUnSubAPIState(false);
          }}
          data={{
            title: "Oops!",
            msg: unSubDataError.data.error,
          }}
        />
      );
    } else if (unSubData) {
      UnSubAPIStateContent = null;
    } else {
      UnSubAPIStateContent = null;
    }
  } else {
    UnSubAPIStateContent = null;
  }

  //#endregion

  return (
    <Fragment>
      {subListAPIStateContent}
      {UnSubAPIStateContent}
      {unSubConfirmDialog}
    </Fragment>
  );
};

export default ImiSubscriptions;
